import zero from "./zero";
import one from "./one";
import two from "./two";
import three from "./three";
import four from "./four";
import five from "./five";
import six from "./six";
import seven from "./seven";

export default [zero, one, two, three, four, five, six, seven];
